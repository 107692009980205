import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { useState } from "react";
import Link from "next/link";

import styles from "../styles/App.module.css";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <section className="section has-background-primary has-text-centered">
        <h1 className="title has-text-white">Fun Marion</h1>
        <h2 className="subtitle has-text-white">
          Your one stop shop for fun events around Marion, NC
        </h2>
        <div className="tags are-medium is-centered has-text-weight-medium">
          <div className="tag is-link">
            <Link href="/">
              <a className={styles.plainLink}>Events</a>
            </Link>
          </div>
          <div className="tag is-link">
            <Link href="/subscribe">
              <a className={styles.plainLink}>Subscribe</a>
            </Link>
          </div>
          <div className="tag is-link">
            <Link href="/submit">
              <a className={styles.plainLink}>Submit</a>
            </Link>
          </div>
          <div className="tag is-link">
            <Link href="/contact">
              <a className={styles.plainLink}>Contact</a>
            </Link>
          </div>
        </div>
      </section>

      <Component {...pageProps} />

      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            Copyright 2022 |{" "}
            <Link href="/privacy" scroll={true}>
              <a>Privacy policy</a>
            </Link>
          </p>
        </div>
      </footer>
    </>
  );
}

export default MyApp;
